//react
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
//ui and components
import { BdsToaster } from '@core/components/atoms';
//functional and data
import { Mutation } from '@core/models/api';
import { errorHandler } from '@core/utils';
import {
  Region,
  PotentialFlight,
  PassengerMatch,
  MarketingMessage,
  PexelImage,
  AirlineMarketingTemplate,
} from '@app/models/api';
import {
  getRegionsList,
  getPotentialFlights,
  getPotentialFlightsX,
  getPassengerMatches,
  generateMarketingMessage,
  searchForImage,
  sendMarketingMessage,
  getMarketingTemplate,
} from '@app/api';

export function useGetRegionsList() {
  const data = useQuery(
    'airline-useGetRegionsList',
    async (): Promise<Region[]> => getRegionsList(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return data;
}

export function useGetMarketingTemplate(airlineId: number) {
  const data = useQuery(
    ['airline-useGetMarketingTemplate', airlineId],
    async (): Promise<AirlineMarketingTemplate> =>
      getMarketingTemplate(airlineId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return data;
}

export function useGetPotentialFlights(
  airlineId: number,
  searchWeeksOut: number,
  bustCache: boolean,
) {
  const data = useQuery(
    ['airline-useGetPotentialFlights', airlineId, searchWeeksOut, bustCache],
    async (): Promise<PotentialFlight[]> =>
      getPotentialFlights(airlineId, searchWeeksOut, bustCache),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
    },
  );

  return data;
}

//--------------------------------------

export function useGetPotentialFlightsX(
  airlineId: number,
  searchWeeksOut: number,
  //bustCache: boolean,
  bustCacheCurrValue: number,
  bustCachePrevValue: number,
  setBustCachePrevValueStateFunc: (newVal: number) => void,
) {
  const data = useQuery(
    [
      'airline-useGetPotentialFlightsX',
      airlineId,
      searchWeeksOut,
      bustCacheCurrValue > bustCachePrevValue,
    ],
    async (): Promise<PotentialFlight[]> =>
      //getPotentialFlightsX(airlineId, searchWeeksOut, bustCache),
      getPotentialFlightsX(
        airlineId,
        searchWeeksOut,
        bustCacheCurrValue > bustCachePrevValue,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      onSuccess: async () => {
        if (bustCacheCurrValue > bustCachePrevValue) {
          setBustCachePrevValueStateFunc(bustCacheCurrValue);
        }
      },
    },
  );

  return data;
}

export interface PassengerMatchWithCallback {
  airlineId: number;
  flightDate: string;
  destinationCityName: string;
  matchBirthday: boolean;
  matchAnniversary: boolean;
  matchInterest: boolean;
  matchPastFlight: boolean;
  callbackFunc: (data: unknown) => void;
}

export function useGetPassengerMatches(props: PassengerMatchWithCallback) {
  const data = useQuery(
    [
      'airline-useGetPassengerMatches',
      props.airlineId,
      props.flightDate,
      props.destinationCityName,
      props.matchBirthday,
      props.matchAnniversary,
      props.matchInterest,
      props.matchPastFlight,
    ],
    async (): Promise<PassengerMatch[]> =>
      getPassengerMatches(
        props.airlineId,
        props.flightDate,
        props.destinationCityName,
        props.matchBirthday,
        props.matchAnniversary,
        props.matchInterest,
        props.matchPastFlight,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  props.callbackFunc(data.data);
  return data;
}

export function useGenerateMarketingMessage(
  passengerMatch: PassengerMatch | undefined,
) {
  const data = useQuery(
    [
      'airline-useGenerateMarketingMessage',
      passengerMatch?.passengerId,
      passengerMatch?.destinationCityName,
      passengerMatch?.isBirthday,
      passengerMatch?.isAnniversary,
      passengerMatch?.isPastFlight,
    ],
    async (): Promise<MarketingMessage> =>
      generateMarketingMessage(passengerMatch),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return data;
}

export function useSearchForImage(query: string) {
  const data = useQuery(
    ['airline-useSearchForImage', query],
    async (): Promise<PexelImage> => searchForImage(query),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
  return data;
}

export function useSendMarketingMessage({ onSuccess, onError }: Mutation) {
  const mutation = useMutation(sendMarketingMessage, {
    onSuccess: async () => {
      BdsToaster({
        kind: 'success',
        title: 'Success',
        subTitle: 'Email sent',
        toastId: 'airline-useSendMarketingMessage-success',
      });
      onSuccess && onSuccess();
    },
    onError: (error) => {
      errorHandler({
        error,
        toastId: 'airline-useSendMarketingMessage-failure',
        kind: 'error',
        title: 'Error',
        clientMessage: 'Failed to send email',
      });

      onError && onError();
    },
  });

  return mutation;
}

function getPotentialFlightForDestination(
  airlineDestinationId: any,
  searchWeeksOut: number,
  bustCache: boolean,
): Promise<PotentialFlight> {
  throw new Error('Function not implemented.');
}
