//ui and components
import { BdsToaster } from '@core/components/atoms';
//functional and data
import { AxiosError, AxiosResponse } from 'axios';
import { ApiResponseCode } from '@core/api';

interface ErrorHandlerProps {
  error: AxiosResponse | Error | unknown;
  toastId: string;
  kind: string;
  title?: string;
  clientMessage?: string;
}

export const errorHandler = ({
  error,
  toastId,
  kind,
  title,
  clientMessage,
}: ErrorHandlerProps) => {
  if (!error) return;

  let message = clientMessage || '';
  if (error instanceof AxiosError) {
    const errorResponse = error.response?.data;

    if (
      errorResponse &&
      ![
        ApiResponseCode.Unauthorized,
        ApiResponseCode.ServiceUnavailable,
      ].includes(errorResponse.status)
    ) {
      message = (errorResponse.title as string) || errorResponse;
    }
  } else if (error instanceof Error) {
    message = `${message}: ${error.message}`;
  }

  return BdsToaster({
    kind: kind,
    title: title || '',
    subTitle: message,
    toastId: toastId,
  });
};
