//ui and components
import { HomePage } from '@app/components/pages';
import { AirlineHomePage } from '@app/components/pages/airline';
//import { SystemTeamMemberEdit } from '@approot/src/app/components/pages/users';
//functional and data
import {
  MenuItemCollection,
  NavMapType,
  NavMapRecord,
} from '@app/models/navigation';
import { ActionType } from '@app/models/security';

//This defines the types of pages you can include in the main menu
export const navMap: NavMapRecord = {
  Home: {
    type: NavMapType.Route,
    icon: 'home',
    path: `/`,
    component: HomePage,
    exact: true,
  },
  'Airline PoC': {
    type: NavMapType.Menu,
    icon: 'edit',
  },
  Airline: {
    type: NavMapType.Route,
    icon: 'edit',
    path: `/airline`,
    component: AirlineHomePage,
  },
  // 'Edit Users': {
  //   type: NavMapType.Route,
  //   icon: 'home',
  //   path: `/users`,
  //   component: SystemTeamMemberEdit,
  // },
};

//This defines the main menu - the 'name' is the type of page, which is key to navMap: NavMapRecord above
export const menuItems: MenuItemCollection[] = [
  {
    subMenus: [
      {
        name: 'Home',
        subMenus: [],
        subRoutes: [`/`],
      },
    ],
  },
];
