//react
import { useHistory } from 'react-router-dom';
//ui and components
import { EmptyPageTemplate } from '@core/components/templates';
import { Button } from '@bain/design-system';

export const AccessDeniedPage = () => {
  const history = useHistory();

  const goToHomePage = () => {
    history.push('/');
  };

  return (
    <EmptyPageTemplate
      title="Access Denied"
      description="You do not have access to this page. Click below to return to the homepage or contact us if you have any questions."
    >
      <div>
        <Button kind="secondary" onClick={goToHomePage}>
          Go to Home page
        </Button>
      </div>
    </EmptyPageTemplate>
  );
};
