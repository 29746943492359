//functional and data
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { oktaAuthConfig } from '@approot/config.json';

export class OktaService {
  private static instance: OktaService;
  private readonly oktaAuth: OktaAuth;
  private static lastRenew: number;
  private static expireEarlyMilliseconds: number;

  private constructor(oktaOptions: OktaAuthOptions) {
    this.oktaAuth = new OktaAuth(oktaOptions);
  }

  public static getOktaAuth(): OktaAuth {
    if (!OktaService.instance) {
      OktaService.instance = new OktaService(oktaAuthConfig);

      OktaService.lastRenew = 0;
      OktaService.expireEarlyMilliseconds =
        oktaAuthConfig.expireEarlySeconds * 1000;
    }

    return OktaService.instance.oktaAuth;
  }

  public static getAccessToken(): string | undefined {
    const oktaAuth = OktaService.getOktaAuth();
    return oktaAuth.getAccessToken();
  }

  public static async renew(): Promise<void> {
    const nowDate = Date.now();
    const nextRefresh =
      OktaService.lastRenew + OktaService.expireEarlyMilliseconds;

    if (nowDate > nextRefresh) {
      const oktaAuth = OktaService.getOktaAuth();
      await oktaAuth.tokenManager.renew('accessToken');
      OktaService.lastRenew = Date.now();
    }
  }
}
