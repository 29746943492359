//ui and components
import { CoreToasterType } from '@core/models/enum';
import { BdsToaster } from '@core/components/atoms';

export function getLocalStorageToken(): string {
  const isCookieEnabled = navigator.cookieEnabled;
  if (!isCookieEnabled) {
    BdsToaster({
      kind: 'error',
      title: 'Error',
      subTitle:
        'We cannot get access to the cookies. Please make sure cookies are enabled in your browser.',
      toastId: `${CoreToasterType.cookieNotEnabled}`,
    });

    return '{}';
  }
  return localStorage.getItem('okta-token-storage') || '{}';
}

export function setCallbackPath(path: string) {
  localStorage.setItem('callback-path', path);
}

export function removeCallbackPath() {
  localStorage.removeItem('callback-path');
}
