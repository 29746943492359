//react
import { Redirect } from 'react-router-dom';
//ui and components
import { OktaSignInWidget } from './';
//functional and data
import { useOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { Tokens } from '@okta/okta-auth-js/lib/types/Token';

export const OktaLogin = () => {
  const authSnapshot: IOktaContext = useOktaAuth();

  const onSuccess = async (tokens: Tokens): Promise<void> => {
    await authSnapshot.oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (error?: Error): void => {
    console.error('Failed to login', error);
  };

  const authenticated = !!authSnapshot.authState?.isAuthenticated;

  return authenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <OktaSignInWidget onSuccess={onSuccess} onError={onError} />
  );
};
