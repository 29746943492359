//ui and components
import { toast, ToastOptions } from 'react-toastify';
import { ToastNotification } from '@bain/design-system';

//setting standardized properties and behavior of the react-toastify 'container'
const toastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  closeButton: false,
  style: {
    width: '300px', //hardcoded to match BDS ToastNotification width
    left: '60px', //hardcoded to adjust for positioning on bottom right
  },
};

interface BdsToasterProps {
  kind: string; //ToastNotification kind - success, error, info, warning, etc.
  title?: string;
  subTitle: string;
  toastId: string; //react-toastify requires a unique Id (string) for each toast
}

//Call this function to toast. Example:
// BdsToaster({
//   kind: 'success',
//   title: 'My title',
//   subTitle:
//     'My very long winded message string which is probably too long for a toast.',
//   toastId: 'someUniqueId',
// });
export const BdsToaster = ({
  kind,
  title = ' ', //if not provided, default to a space so it takes some vertical space to look decent
  subTitle,
  toastId,
}: BdsToasterProps) => {
  toastOptions.toastId = toastId;

  //this line to remove the default box shadow of react-toastify 'container'
  toastOptions.style = {
    ...toastOptions.style,
    boxShadow: 'none',
  };

  return toast(
    <ToastNotification
      lowContrast
      kind={kind}
      subtitle={subTitle}
      title={title}
    />,
    toastOptions,
  );
};
