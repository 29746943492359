export enum ApiResponseCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  PartialResponse = 206,
  BadRequest = 400,
  Forbidden = 403,
  Unauthorized = 401,
  NotFound = 404,
  TooLarge = 413,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}
