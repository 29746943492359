//react
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
//functional and data
import { User } from '@core/models/api';

export type CurrentUserContextInterface = User;

function createCurrentUserCtx<CurrentUserContextInterface>(
  defaultValue: CurrentUserContextInterface | null,
) {
  const defaultSetCurrentUser: Dispatch<
    SetStateAction<typeof defaultValue>
  > = () => defaultValue;

  const ctx = createContext({
    currentUser: defaultValue,
    setCurrentUser: defaultSetCurrentUser,
  });

  const Provider = (props: { children: ReactNode }) => {
    const [currentUser, setCurrentUser] = useState(defaultValue);
    return <ctx.Provider value={{ currentUser, setCurrentUser }} {...props} />;
  };
  return [ctx, Provider] as const;
}

const [CurrentUserContext, CurrentUserProvider] =
  createCurrentUserCtx<CurrentUserContextInterface>(null);

export { CurrentUserContext, CurrentUserProvider };
