//functional and data
import { RoleType } from '@app/models/security';
import { User } from '@core/models/api';
import { ActionType } from '@app/models/security';

export const permissionsList: Record<number, ActionType[]> = {
  [RoleType.User]: [],
  [RoleType.Editor]: [ActionType.doEditorAction],
  [RoleType.ClientAdmin]: [
    ActionType.doEditorAction,
    ActionType.doClientAdminAction,
  ],
  [RoleType.BainAdmin]: [
    ActionType.doEditorAction,
    ActionType.doClientAdminAction,
    ActionType.doBainAdminAction,
  ],
};

export const hasPermissionTo = (
  currentUser: User | null,
  action: ActionType,
) => {
  if (!currentUser) return false;
  let currentLevel = currentUser.systemRoleId;

  while (currentLevel > 0) {
    const actions = permissionsList[currentLevel];

    if (actions.includes(action)) return true;

    currentLevel -= 1;
  }

  return false;
};
