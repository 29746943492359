import { axiosInstance } from '@core/api';
import { Patch } from '@core/models/api';
import {
  Region,
  AirlineDestination,
  PotentialFlight,
  PassengerMatch,
  MarketingMessage,
  PexelImage,
  EmailContent,
  AirlineMarketingTemplate,
} from '@app/models/api';
import moment from 'moment';

export const getRegionsList = async (): Promise<Region[]> => {
  const { data } = await axiosInstance.get<Region[]>(`airline/region`);
  return data;
};

export const getPotentialFlightsX = async (
  airlineId: number,
  searchWeeksOut: number,
  bustCache: boolean,
): Promise<PotentialFlight[]> => {
  let data: PotentialFlight[] = [];
  const destinations: Promise<AirlineDestination[]> =
    getAirlineDestinationsList(airlineId);

  await Promise.resolve(destinations).then(async function (destinationValues) {
    const promises: Promise<PotentialFlight>[] = [];
    destinationValues?.forEach((element) => {
      promises.push(
        getPotentialFlightForDestination(
          element.airlineDestinationId,
          searchWeeksOut,
          bustCache,
        ),
      );
    });

    await Promise.all(promises).then(function (potentialFlights) {
      data = potentialFlights.filter(
        (flight) => Object.keys(flight).length !== 0,
      ); //remove null values (no flights found)
    });
  });
  return data;
};

export const getAirlineDestinationsList = async (
  airlineId: number,
): Promise<AirlineDestination[]> => {
  const { data } = await axiosInstance.get<AirlineDestination[]>(
    `airline/airline-destination/${airlineId}`,
  );
  return data;
};

export const getMarketingTemplate = async (
  airlineId: number,
): Promise<AirlineMarketingTemplate> => {
  const { data } = await axiosInstance.get<AirlineMarketingTemplate>(
    `airline/marketing_template/${airlineId}`,
  );
  return data;
};

export const getPotentialFlights = async (
  airlineId: number,
  searchWeeksOut: number,
  bustCache: boolean,
): Promise<PotentialFlight[]> => {
  const { data } = await axiosInstance.get<PotentialFlight[]>(
    `airline/potential-flight2/${airlineId}?searchWeeksOut=${searchWeeksOut}&bustCache=${bustCache}`,
    {
      timeout: 250000,
    },
  );
  return data;
};

export const getPotentialFlightForDestination = async (
  airlineDestinationId: number,
  searchWeeksOut: number,
  bustCache: boolean,
): Promise<PotentialFlight> => {
  const { data } = await axiosInstance.get<PotentialFlight>(
    `airline/potential-flight-for-destination/${airlineDestinationId}?searchWeeksOut=${searchWeeksOut}&bustCache=${bustCache}`,
    {
      timeout: 250000,
    },
  );
  return data;
};

export const getPassengerMatches = async (
  airlineId: number,
  flightDate: string,
  destinationCityName: string,
  matchBirthday: boolean,
  matchAnniversary: boolean,
  matchInterest: boolean,
  matchPastFlight: boolean,
): Promise<PassengerMatch[]> => {
  const { data } = await axiosInstance.get<PassengerMatch[]>(
    `airline/passenger-match/${airlineId}?flightDate=${flightDate}&destinationCityName=${destinationCityName}&matchBirthday=${matchBirthday}&matchAnniversary=${matchAnniversary}&matchInterest=${matchInterest}&matchPastFlight=${matchPastFlight}`,
  );
  return data;
};

export const generateMarketingMessage = async (
  passengerMatch: PassengerMatch | undefined,
): Promise<MarketingMessage> => {
  if (passengerMatch !== undefined) {
    const { data } = await axiosInstance.get<MarketingMessage>(
      `airline/generate-marketing-message/${
        passengerMatch.passengerId
      }?originCityName=${
        passengerMatch.potentialFlight.originCityName
      }&destinationCityName=${
        passengerMatch.destinationCityName
      }&toFlightDate=${moment(
        passengerMatch.potentialFlight.toFlightDeparture,
      ).format('MMMM DD, YYYY')}&returnFlightDate=${moment(
        passengerMatch.potentialFlight.returnFlightDeparture,
      ).format(
        'MMMM DD, YYYY',
      )}&flightPrice=${passengerMatch.potentialFlight.price.toString()}&businessClassPriceFormatted=${passengerMatch.potentialFlight.businessClassPriceFormatted.toString()}&isBirthday=${
        passengerMatch.isBirthday
      }&isAnniversary=${
        passengerMatch.isAnniversary
      }&doMatchingInterests=true&isPastFlight=${
        passengerMatch.isPastFlight
      }&checkInDate=${moment(
        passengerMatch.potentialFlight.toFlightArrival,
      ).format('YYYY-MM-DD')}&checkOutDate=${moment(
        passengerMatch.potentialFlight.returnFlightDeparture,
      ).format('YYYY-MM-DD')}`,
    );
    return data;
  }
  return {
    message: '',
    tagline: '',
    salutation: '',
    hotelMessage: '',
    flightMessage: '',
    useMilesMessage: '',
    businessClassMessage: '',
    bookNowMessage: '',
    itineraryMessage: '',
    imageUrl: '',
  };
};

export const searchForImage = async (query: string): Promise<PexelImage> => {
  const { data } = await axiosInstance.get<PexelImage>(
    `search-for-image?query=${query}`,
  );
  return data;
};

export const sendMarketingMessage = async ({
  passengerId,
  path,
}: {
  passengerId: number;
  path: Patch[];
}) => {
  await axiosInstance.post<EmailContent>(
    `airline/send-marketing-message/${passengerId}`,
    path,
  );
};
