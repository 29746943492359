//react
import { useContext, useEffect } from 'react';
import {
  Switch,
  Route as RouterRoute,
  useLocation,
  useHistory,
} from 'react-router-dom';
//ui and components
import { HeaderWithNavigation } from './';
import { BdsLoading } from '@core/components/atoms';
//functional and data
import { useOktaAuth, LoginCallback } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { RouteConfig } from '@app/components/navigation';
import { CurrentUserContext } from '@app/contexts';
import { Environment } from '@core/services';
import { OktaLogin } from '@core/components/okta';
import { menuItems } from '../navigation/Routes';
import { setCallbackPath, removeCallbackPath } from '@core/utils/';
import { useGetCurrentUser } from '@app/hooks';
import { PendoService } from '@app/services';

export const Layout = () => {
  const isLocalhost = Environment.isLocalhost();
  const isForbidden = false; //this is hardcoded. so what is the purpose?
  const authSnapshot: IOktaContext = useOktaAuth();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const authenticated = !!authSnapshot?.authState?.isAuthenticated;
  const { data: user, isLoading } = useGetCurrentUser(authenticated);

  useEffect(() => {
    if (authenticated) {
      removeCallbackPath();

      if (user) {
        setCurrentUser(user);
        PendoService.initializePendo(user);
      }
    } else setCurrentUser(null);
  }, [authenticated, setCurrentUser, user]);

  const handleSignOut = async () => {
    if (authenticated && authSnapshot.oktaAuth) {
      await authSnapshot.oktaAuth.signOut({
        postLogoutRedirectUri: Environment.getLogoutRedirectUri(),
      });
      // setCallbackPath('/mypoc/');
      setCallbackPath('/');
    }
  };

  return (
    <>
      {!isForbidden && authenticated && (
        <HeaderWithNavigation menuItems={currentUser ? menuItems : []} />
      )}

      {authenticated ? (
        <div>
          <section>
            <main className="cds--content">
              {isLoading ? (
                <BdsLoading withOverlay={false} />
              ) : (
                <RouteConfig forbidden={isForbidden} />
              )}
            </main>
          </section>
        </div>
      ) : (
        <Switch>
          <RouterRoute path="/login/callback" component={LoginCallback} />
          <RouterRoute path="*" render={() => <OktaLogin />} />
        </Switch>
      )}
    </>
  );
};
