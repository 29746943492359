import { axiosInstance } from '@core/api';
import { Patch } from '@core/models/api';
import { User, SystemTeamMember, SystemRole } from '@core/models/api';

//Me

export const getUser = async (): Promise<User> => {
  const { data } = await axiosInstance.get<User>(`users/me`);

  return data;
};

//SystemTeamMember

export const getSystemTeamMembersList = async (): Promise<
  SystemTeamMember[]
> => {
  const { data } = await axiosInstance.get<SystemTeamMember[]>(
    `users/system-member`,
  );
  return data;
};

export const updateSystemTeamMember = async ({
  id,
  path,
}: {
  id: number;
  path: Patch[];
}) => {
  await axiosInstance.patch<SystemTeamMember>(
    `users/system-member/${id}`,
    path,
  );
};

export const createSystemTeamMember = async ({ path }: { path: Patch[] }) => {
  await axiosInstance.post<SystemTeamMember>(`users/system-member`, path);
};

export const deleteSystemTeamMember = async ({ id }: { id: number }) => {
  await axiosInstance.delete<SystemTeamMember>(`users/system-member/${id}`);
};

//SystemRole

export const getSystemRolesList = async ({
  filterForPrivilege,
}: {
  filterForPrivilege: boolean;
}): Promise<SystemRole[]> => {
  const { data } = await axiosInstance.get<SystemRole[]>(
    `users/system-role/${filterForPrivilege}`,
  );
  return data;
};
