//react
import { Switch, Redirect } from 'react-router-dom';
//ui and components
import { AccessDeniedPage } from '@core/components/pages/other';
//functional and data
import { SecureRoute } from '@okta/okta-react';
import { Route } from '@app/models/navigation';
import { navMap, Navigation } from './';

interface RouteConfigProps {
  forbidden?: boolean;
}

interface PrivateRouteProps extends Route, RouteConfigProps {}

const PrivateRoute = ({
  forbidden,
  component: Component,
  ...route
}: PrivateRouteProps) => {
  return forbidden ? (
    <AccessDeniedPage />
  ) : (
    <SecureRoute {...route}>
      <Component />
    </SecureRoute>
  );
};

export const RouteConfig = ({ forbidden }: RouteConfigProps) => {
  const routes = Navigation.transformMenuToRoutes(navMap);
  return (
    <Switch>
      {routes.map((route: Route) => (
        <PrivateRoute forbidden={forbidden} key={route.path} {...route} />
      ))}
      <SecureRoute exact path="*">
        <Redirect to="/" />
      </SecureRoute>
    </Switch>
  );
};
