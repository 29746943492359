//ui and components
import { EmptyPageTemplate } from '@core/components/templates';
import { Environment } from '@core/services';

export const ErrorPage = (props: { error: string; errorStack?: string }) => {
  return (
    <EmptyPageTemplate title="Oops!">
      <h4>{props.error}</h4>

      {Environment.isDevelopment() && (
        <>
          <br />
          <h5>{props.errorStack}</h5>
        </>
      )}
      <h2>
        Please refresh your page. If you continue to have issues, please{' '}
        <a href={`mailto:${process.env.NX_SUPPORT_EMAIL}`}>contact us</a>
      </h2>
    </EmptyPageTemplate>
  );
};
