//react
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

interface ChildrenProps {
  children?: ReactNode;
}

export class QueryProvider {
  private static instance: QueryClient;

  private static getInstance(): QueryClient {
    if (!QueryProvider.instance) {
      QueryProvider.instance = new QueryClient();
    }

    return QueryProvider.instance;
  }

  public static get queryClient() {
    return QueryProvider.getInstance();
  }

  public static async pushItem(item: unknown, queryKey: string | unknown[]) {
    const queryClient = QueryProvider.queryClient;
    await queryClient.cancelQueries(queryKey);

    const previousItems = queryClient.getQueryData<unknown[]>(queryKey);

    if (previousItems) {
      queryClient.setQueryData<unknown[]>(queryKey, [...previousItems, item]);
    }
  }

  public static async removeCache(queryKey: string | unknown[]) {
    const queryClient = QueryProvider.queryClient;
    queryClient.removeQueries({ queryKey, exact: true });
  }
}

export const ReactQueryProvider = ({ children }: ChildrenProps) => {
  return (
    <QueryClientProvider client={QueryProvider.queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};
