//react
import React, { Component, ErrorInfo, ReactNode } from 'react';
//ui and components
import { ErrorPage } from '@core/components/pages/other';

interface Props {
  children?: ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {};

  public static getDerivedStateFromError(error: Error): State {
    console.error(`Uncaught error:`, error);
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorPage
          error={this.state.error.message}
          errorStack={this.state.errorInfo?.componentStack}
        />
      );
    }

    return this.props.children;
  }
}
