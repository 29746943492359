//ui and components
import { AccessDeniedPage } from '@core/components/pages/other';
//functional and data
import { MenuItem } from '@app/models/navigation';
import { NavMapType, NavMapRecord, Route } from '@app/models/navigation';
import { navMap } from './';

export class Navigation {
  private static instance: Navigation;
  private flatRoutes: Route[] = [];

  private constructor(menu: NavMapRecord) {
    this.flatRoutes = Navigation.flattenRoutes(menu);
  }

  private static flattenRoutes(menu: NavMapRecord): Route[] {
    const flat: Route[] = [];
    Object.entries(menu)
      .filter(([_, router]) => router.type === NavMapType.Route)
      .forEach(([key, router]) => {
        flat.push({
          name: key.replace(' ', ''),
          component: router.component ?? AccessDeniedPage,
          exact: router.exact,
          path: router.path,
        });
      });

    return flat;
  }

  public static transformMenuToRoutes(menu: NavMapRecord): Route[] {
    if (!Navigation.instance && menu) {
      Navigation.instance = new Navigation(menu);
    }

    return Navigation.instance.flatRoutes;
  }

  public static isRouteSelected(path: string, menuItem: MenuItem): boolean {
    const routePath = navMap[menuItem.name].path;

    let isRouteSelected = path === (routePath || '');

    if (!isRouteSelected && menuItem.subRoutes?.length) {
      isRouteSelected = menuItem.subRoutes?.some((url) => path.includes(url));
    }

    return isRouteSelected;
  }
}
