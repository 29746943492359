//ui and components
import { Loading } from '@bain/design-system';

interface BdsLoadingProps {
  withOverlay: boolean;
}

export const BdsLoading = ({ withOverlay }: BdsLoadingProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '30px',
        position: 'relative',
      }}
    >
      <Loading withOverlay={withOverlay} />
    </div>
  );
};
