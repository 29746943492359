//react
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
//ui and components
import { BdsToaster } from '@core/components/atoms';
//functional and data
import { Mutation, SystemTeamMember, SystemRole } from '@core/models/api';
import {
  getUser,
  getSystemTeamMembersList,
  updateSystemTeamMember,
  createSystemTeamMember,
  deleteSystemTeamMember,
  getSystemRolesList,
} from '@app/api';
import { errorHandler } from '@core/utils';
import { CoreToasterType } from '@core/models/enum';

//Me

export function useGetCurrentUser(isAuthenticated: boolean) {
  const data = useQuery('user-useGetCurrentUser', getUser, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: isAuthenticated,
  });

  errorHandler({
    error: data.error,
    toastId: CoreToasterType.getCurrentUser,
    kind: 'error',
    title: 'Error',
    clientMessage: 'Failed to get current user',
  });

  return data;
}

//SystemTeamMember

export function useGetSystemTeamMembersList() {
  const data = useQuery(
    'user-useGetSystemTeamMembersList',
    async (): Promise<SystemTeamMember[]> => getSystemTeamMembersList(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return data;
}

export function useUpdateSystemTeamMember({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: Mutation) {
  const mutation = useMutation(updateSystemTeamMember, {
    onMutate: () => {
      onMutate && onMutate();
    },
    onSuccess: async () => {
      BdsToaster({
        kind: 'success',
        title: 'Success',
        subTitle: 'User updated',
        toastId: 'user-useUpdateSystemTeamMember-success',
      });
      onSuccess && onSuccess();
    },
    onError: (error) => {
      errorHandler({
        error,
        toastId: 'user-useUpdateSystemTeamMember-failure',
        kind: 'error',
        title: 'Error',
        clientMessage: 'Failed to update user',
      });

      onError && onError();
    },
    onSettled: () => {
      onSettled && onSettled();
    },
  });

  return mutation;
}

export function useCreateSystemTeamMember({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: Mutation) {
  const mutation = useMutation(createSystemTeamMember, {
    onMutate: () => {
      onMutate && onMutate();
    },
    onSuccess: async () => {
      BdsToaster({
        kind: 'success',
        title: 'Success',
        subTitle: 'User created',
        toastId: 'user-useCreateSystemTeamMember-success',
      });
      onSuccess && onSuccess();
    },
    onError: (error) => {
      errorHandler({
        error,
        toastId: 'user-useCreateSystemTeamMember-failure',
        kind: 'error',
        title: 'Error',
        clientMessage: 'Failed to create user',
      });

      onError && onError();
    },
    onSettled: () => {
      onSettled && onSettled();
    },
  });

  return mutation;
}

export function useDeleteSystemTeamMember({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: Mutation) {
  const mutation = useMutation(deleteSystemTeamMember, {
    onMutate: () => {
      onMutate && onMutate();
    },
    onSuccess: async () => {
      BdsToaster({
        kind: 'success',
        title: 'Success',
        subTitle: 'User deleted',
        toastId: 'user-useDeleteSystemTeamMember-success',
      });
      onSuccess && onSuccess();
    },
    onError: (error) => {
      errorHandler({
        error,
        toastId: 'user-useDeleteSystemTeamMember-failure',
        kind: 'error',
        title: 'Error',
        clientMessage: 'Failed to delete user',
      });

      onError && onError();
    },
    onSettled: () => {
      onSettled && onSettled();
    },
  });

  return mutation;
}

//SystemRole

export function useGetSystemRolesList({
  filterForPrivilege,
}: {
  filterForPrivilege: boolean;
}) {
  const data = useQuery(
    ['user-useGetSystemRolesList', filterForPrivilege],
    async (): Promise<SystemRole[]> =>
      getSystemRolesList({ filterForPrivilege }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return data;
}
