//React
import { useContext } from 'react';
//ui and components
import {
  HeaderName,
  SkipToContent,
  HeaderBainLogo,
  HeaderContainer,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderMenu,
} from '@bain/design-system';
import { BdsDynamicIcon } from '@core/components/atoms';
//funtional and data
import {
  MenuItem,
  MenuItemCollection,
  NavMapType,
} from '@app/models/navigation';
import { hasPermissionTo } from '@app/utils';
import { navMap } from '../navigation/Routes';
import { CurrentUserContext } from '@app/contexts';

interface HeaderWithNavigationProps {
  menuItems: MenuItemCollection[];
}

export const HeaderWithNavigation = ({
  menuItems,
}: HeaderWithNavigationProps) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <HeaderContainer
      render={() => (
        <div
          style={{
            borderBottom: '#ddd 1px solid',
          }}
        >
          <HeaderBainLogo variant="icon" prefix="">
            <SkipToContent />
            <HeaderName href="#" prefix="">
              Airline Marketing PoC
            </HeaderName>
            <HeaderNavigation aria-label="">
              {menuItems.map((block, index) => {
                return block.subMenus.map((item: MenuItem, index: number) => {
                  if (
                    !item.actionType ||
                    hasPermissionTo(currentUser, item.actionType)
                  ) {
                    if (
                      navMap[item.name].type === NavMapType.Route ||
                      navMap[item.name].type === NavMapType.Separator
                    ) {
                      return (
                        <HeaderMenuItem href={navMap[item.name].path}>
                          <BdsDynamicIcon
                            type={navMap[item.name].icon || ''}
                            data={{
                              size: 24,
                              style: { verticalAlign: 'bottom' },
                            }}
                          />
                          {item.name}
                        </HeaderMenuItem>
                      );
                    } else if (navMap[item.name].type === NavMapType.Menu) {
                      return (
                        <HeaderMenu
                          aria-label={item.name}
                          menuLinkName={item.name}
                        >
                          {item.subMenus?.map((subItem: MenuItem) => {
                            if (
                              !subItem.actionType ||
                              hasPermissionTo(currentUser, subItem.actionType)
                            ) {
                              if (
                                navMap[subItem.name].type ===
                                  NavMapType.Route ||
                                navMap[subItem.name].type ===
                                  NavMapType.Separator
                              ) {
                                return (
                                  <HeaderMenuItem
                                    href={navMap[subItem.name].path}
                                  >
                                    <BdsDynamicIcon
                                      type={navMap[subItem.name].icon || ''}
                                      data={{
                                        size: 24,
                                        style: { verticalAlign: 'bottom' },
                                      }}
                                    />
                                    {subItem.name}
                                  </HeaderMenuItem>
                                );
                              } else {
                                return <span></span>;
                              }
                            } else {
                              return <span></span>;
                            }
                          })}
                        </HeaderMenu>
                      );
                    } else {
                      return <span></span>;
                    }
                  } else {
                    return <span></span>;
                  }
                });
              })}
            </HeaderNavigation>
          </HeaderBainLogo>
        </div>
      )}
    />
  );
};
