export enum PatchOperations {
  add = 'add',
  remove = 'remove',
  replace = 'replace',
  copy = 'copy',
  move = 'move',
  test = 'test',
}

export interface Patch {
  op: PatchOperations;
  path: string;
  value: string | number | boolean | null;
  operationType?: string;
  from?: string;
}
