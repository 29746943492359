//react
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
//ui and components
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './app/app';
//functional and data
import { ErrorBoundary } from '@core/components/templates';
import { Environment } from '@core/services';

const agGridLicenseKey = Environment.getAgGridLicenseKey();
LicenseManager.setLicenseKey(agGridLicenseKey);

ReactDOM.render(
  <StrictMode>
    {/* <BrowserRouter basename="/mypoc"> */}
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
