export const EmptyPageTemplate = (props: {
  title: string;
  children?: React.ReactNode;
  description?: string;
}) => {
  return (
    <div>
      <div>
        <h2>{props.title}</h2>
        {props.description && <h4>{props.description}</h4>}
        <div>{props.children}</div>
      </div>
    </div>
  );
};
