//react
import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
//ui and components
import {
  Button,
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  ClickableTile,
} from '@bain/design-system';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import { BdsLoading } from '@core/components/atoms';
import { AccessDeniedPage } from '@core/components/pages/other';
//functional and data
import { useGetRegionsList } from '@app/hooks';
//security
import { CurrentUserContext } from '@app/contexts';
import { hasPermissionTo } from '@app/utils';
import { ActionType } from '@app/models/security';

export const HomePage = (): React.ReactElement => {
  //setup
  const history = useHistory();
  const { currentUser } = useContext(CurrentUserContext);
  const gridRef = useRef<AgGridReact>(null);
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [open, setOpen] = useState(false);

  //security
  const hasPermission = hasPermissionTo(currentUser, ActionType.doEditorAction);

  //data
  const { data: regions, isLoading: regionsIsLoading = true } =
    useGetRegionsList();

  if (regionsIsLoading) {
    <BdsLoading withOverlay={false} />;
  }

  return (
    <>
      {hasPermission && (
        <>
          <div
            style={{
              display: 'flex',
              marginBottom: '8px',
            }}
          >
            <h2>Select Airline</h2>
          </div>

          {regions?.map((region) => {
            return (
              <>
                <h4>{region.name}</h4>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {region.airlines?.map((airline) => {
                    return (
                      <ClickableTile
                        style={{
                          width: '250px',
                          height: '150px',
                          margin: '10px',
                        }}
                        id={`airline-clickable-tile-${airline.airlineId}`}
                        href={`/airline/${airline.airlineId}`}
                      >
                        <h5>
                          {airline.name} ({airline.airlineCode})
                        </h5>
                        <div
                          style={{
                            height: '4em',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '3',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: airline.logoSvg ? airline.logoSvg : '',
                            }}
                          />
                        </div>
                      </ClickableTile>
                    );
                  })}
                </div>
              </>
            );
          })}
        </>
      )}
      {!hasPermission && <AccessDeniedPage></AccessDeniedPage>}
    </>
  );
};
