import { User } from '@core/models/api';
import { Environment } from '@core/services/';
import { pendoConfig } from '../../../config.json';
import { getLocalStorageToken } from '@core/utils';
import { Tokens } from '@okta/okta-auth-js';

export class PendoService {
  public static initializePendo = (user: User) => {
    if (pendoConfig.isEnabled && !Environment.isLocalhost()) {
      const oktaStorageToken: Tokens = JSON.parse(getLocalStorageToken());
      if (!oktaStorageToken) return;
      const claims = oktaStorageToken.idToken?.claims;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pendo?.initialize({
        visitor: {
          id: claims?.email,
          email: user.email,
          role: user.roleName,
        },
        account: {
          id: Environment.getSubDomain(),
          name: Environment.getSubDomain(),
        },
      });
    }
  };
}
