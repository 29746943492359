//react
import { useEffect, useRef } from 'react';
//ui and components
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
//functional and data
import { Tokens } from '@okta/okta-auth-js/lib/types/Token';
import { Environment } from '@core/services';
import { oktaSignInConfig } from '@approot/config.json';

export const OktaSignInWidget = ({
  onSuccess,
  onError,
}: {
  onSuccess: (values: Tokens) => Promise<void>;
  onError: (reason?: Error) => Promise<void> | void;
}) => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) return;

    const widget = new OktaSignIn({
      ...oktaSignInConfig,
      redirectUri: Environment.getRedirectUri(),
    });

    widget
      .showSignInToGetTokens({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return <div ref={widgetRef} />;
};
