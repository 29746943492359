//react
import React, { Component } from 'react';
//ui and components
import * as Icon from '@carbon/icons-react';

interface BdsDynamicIconProps {
  type: string;
  data: AnyObject;
}

interface AnyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const iconList: AnyObject = {
  home: Icon.Home,
  directionFork: Icon.DirectionFork,
  directionMerge: Icon.DirectionMerge,
  edit: Icon.Edit,
  settings: Icon.Settings,
  userAdmin: Icon.UserAdmin,
  //add icons here from @carbon/icons-react for use
};

//Sample usage:
//<BdsDynamicIcon
//  type={'home'}
//  data={{ size: 24, style: { verticalAlign: 'bottom' } }}
///>
export class BdsDynamicIcon extends Component<BdsDynamicIconProps> {
  render() {
    const TagName = iconList[this.props.type as keyof AnyObject];
    if (!TagName) {
      return null;
    }
    return <TagName {...this.props.data} />;
  }
}
