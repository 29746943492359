//react
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
//ui and components
import { useMediaQuery } from '@core/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from '@app/components/layout';
//functional and data
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
  ReactQueryProvider,
  TabletContext,
  CurrentUserProvider,
} from '@app/contexts';
import { OktaService } from '@core/services';
import { setCallbackPath } from '@core/utils';

export const App = () => {
  const history = useHistory();
  const oktaAuth = OktaService.getOktaAuth();

  const restoreOriginalUri = useCallback(
    async (oktaAuth, originalUri) => {
      if (!oktaAuth.authStateManager.getAuthState().isAuthenticated) {
        //setCallbackPath('/mypoc' + originalUri + window.location.search);
        setCallbackPath(originalUri + window.location.search);
        window.location.replace(
          toRelativeUrl('/login' || originalUri, window.location.origin),
        );
      } else {
        history.replace({
          pathname: toRelativeUrl(
            // originalUri || '/mypoc/',
            originalUri || '/',
            `${window.location.origin}`,
          ),
          search: window.location.search,
        });
      }
    },
    [history],
  );

  const onAuthRequired = () => {
    history.push('/login');
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <TabletContext.Provider value={useMediaQuery('(min-width:930px)')}>
        <ReactQueryProvider>
          <CurrentUserProvider>
            <ToastContainer style={{ width: '385px' }} />
            <Layout />
          </CurrentUserProvider>
        </ReactQueryProvider>
      </TabletContext.Provider>
    </Security>
  );
};

export default App;
