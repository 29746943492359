//ui and components
import { CoreToasterType } from '@core/models/enum';
import { BdsToaster } from '@core/components/atoms';
//functional and data
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from 'axios';
import { ApiResponseCode } from './';
import { Environment, OktaService } from '@core/services';

const apiUrl = Environment.getApiUrl();

const attachJwtInterceptor = (instance: AxiosInstance): void => {
  instance.interceptors.request.use(async (request: AxiosRequestConfig) => {
    const { url } = request;

    if (url) {
      await OktaService.renew();
    }

    const accessToken = OktaService.getAccessToken();
    const isApiUrl = request.baseURL?.startsWith(apiUrl);

    if (accessToken && isApiUrl) {
      return {
        ...request,
        headers: {
          ...request.headers,
          common: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      };
    }

    return request;
  });
};

const getErrorMessage = (
  errorResponse: AxiosResponse,
  clientMessage: string,
) => {
  const { data } = errorResponse;
  return data?.title || clientMessage;
};

const attachTenantHeaderInterceptor = (
  instance: AxiosInstance,
  tenantDomain: string,
): void => {
  instance.interceptors.request.use(async (request: AxiosRequestConfig) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        'X-Original-Host': tenantDomain,
      },
    };
  });
};

const attachUnauthorizedRequest = (instance: AxiosInstance): void => {
  instance.interceptors.response.use(
    (response: AxiosRequestConfig) => response,
    (error: AxiosError) => {
      if (error.response) {
        const { response } = error;
        const { status } = response;

        if ([ApiResponseCode.Unauthorized].includes(status)) {
          const message = getErrorMessage(
            response,
            'Access denied. User not authorized',
          );
          BdsToaster({
            kind: 'error',
            title: 'Unauthorized',
            subTitle: message,
            toastId: `${CoreToasterType.unauthorized}`,
          });
        }

        if (ApiResponseCode.ServiceUnavailable === status) {
          const message = getErrorMessage(response, 'Service Unavailable');
          BdsToaster({
            kind: 'error',
            title: 'Service Unavailable',
            subTitle: message,
            toastId: `${CoreToasterType.serviceUnavailable}`,
          });
        }
      }

      return Promise.reject(error);
    },
  );
};

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

attachJwtInterceptor(axiosInstance);

const tenantDomain = Environment.getTenantDomain();

attachUnauthorizedRequest(axiosInstance);
if (tenantDomain && Environment.isDevelopment()) {
  attachTenantHeaderInterceptor(axiosInstance, tenantDomain);
}
