import { DateTime } from 'luxon';

export const shortDateFormat = 'MM/dd/yyyy';
export const longDateFormat = 'MM/dd/yyyy';

export enum DateFormats {
  deadlineDate = 'MMMM/dd/y',
}

export const formatDate = (
  date: string | undefined,
  format: DateFormats,
): string => {
  if (date) {
    const dateObj = DateTime.fromISO(date);

    return dateObj.toFormat(format);
  }
  return '';
};
